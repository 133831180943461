import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const HomeTwoNavBar = () => {
   const { t, i18n } = useTranslation();
   const navigate = useNavigate();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
   };

   return (
      <>
         <header>
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-9 col-lg-9 col-md-9">
                        <div className="header-info">
                           <a href="tel:+905332691271" style={{ zIndex: 1000 }}><span><i className="fas fa-phone"></i> +90 533 269 12 71</span></a>
                           <a href="tel:+903125574646" style={{ zIndex: 1000 }}> <span><i className="fas fa-phone"></i> +90 312 557 46 46</span></a>
                           <a href="mailto:info@drebrukaragun.com"> <span><i className="fas fa-envelope"></i> info@drebrukaragun.com</span></a>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="header-top-right-btn f-right">
                           <Link to={i18n.language === 'en' ? "/en/appointment" : "/randevu-al"} className="primary_btn">{t('headerTwo.appointment')}</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="top-bar d-block d-md-none">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-9 col-lg-9 col-md-9">
                        <div className="header-info" style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                           <a href="tel:+905332691271" style={{ zIndex: 1000 }}><span><i className="fas fa-phone"></i> +90 533 269 12 71</span></a>
                           <a href="tel:+903125574646" style={{ zIndex: 1000 }}> <span><i className="fas fa-phone"></i> +90 312 557 46 46</span></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- menu-area --> */}
            <div className={stickyMenu ? "sticky_menu header-menu-area sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="logo pos-rel">
                           <Link to={i18n.language === 'en' ? "/en/" : "/"}>
                              <div className='custom-logo-white'>
                                 {t('mainPage.drTitle')}
                              </div>
                           </Link>
                        </div>
                     </div>
                     <div className="col-xl-7 col-lg-8 col-md-6 col-6">
                        <div className="header-right f-right">
                           <div className="header-lang f-right pos-rel d-none d-lg-block">
                              <div className="lang-icon">
                                 {i18n.language === 'en' ? <img src="/img/icon/lang.png" alt="" /> : <img src="/img/icon/turkey.png" alt="" />}
                                 {i18n.language === 'tr' ? <span className='white-color'>TR<i className="fas fa-angle-down white-color"></i></span> :
                                    <span className='white-color'>EN<i className="fas fa-angle-down white-color"></i></span>}
                              </div>
                              <ul className="header-lang-list">
                                 {i18n.language === 'en' ? <li><a onClick={() => {
                                    changeLanguage('tr');
                                    const newPath = window.location.pathname.split('/')
                                    if (newPath[1] === 'en') {
                                       if (window.location.pathname.split('/en/').slice(-1)[0] === '/en') {
                                          window.location.pathname = '/en/';
                                       } else {
                                          navigate('/' + window.location.pathname.split('/en/').slice(-1)[0])
                                       }

                                    }
                                 }}>TR</a></li> : <li><a onClick={() => {
                                    changeLanguage('en');

                                    const newPath = window.location.pathname.split('/')
                                    if (newPath[1] !== 'en') {
                                       navigate('/en' + window.location.pathname)
                                    }
                                 }}>EN</a></li>}
                              </ul>
                           </div>
                        </div>
                        <div className={`header__menu header-menu-white header-right f-right`}>
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to={i18n.language === 'en' ? "/en/" : "/"}>{t('headerTwo.homePage')}</Link>
                                 </li>
                                 <li><Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>{t('headerTwo.resume')}</Link>
                                 </li>
                                 <li><Link to="#">{t('headerTwo.applications')} +</Link>
                                    <ul className="submenu">
                                       <li><Link to={i18n.language === 'en' ? "/en/applications/medical-applications" : "uygulamalar/medikal-uygulamalar"}>{t('headerTwo.medicalDermatology')}</Link></li>

                                       <li className='menu-item'><Link to="#">{t('headerTwo.cosmeticDermatology')} +</Link>
                                          <ul className="submenu" style={{ marginLeft: "100px" }}>

                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/filler-applications" : "uygulamalar/dolgu-uygulamalari"}>{t('headerTwo.fillingApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>{t('headerTwo.faceLiftingApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>{t('headerTwo.mesotherapyApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>{t('headerTwo.deviceOperations')}</Link>
                                             </li>
                                          </ul>
                                       </li>
                                       <li><Link to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>{t('headerTwo.healthyLiving')}</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to={i18n.language === 'en' ? "/en/blogs" : "/blogs"}>{t('headerTwo.blog')}</Link></li>
                                 <li><Link to={i18n.language === 'en' ? "/en/media" : "/medyada-biz"}>{t('headerTwo.weInTheMedia')}</Link></li>
                                 <li><Link to={i18n.language === 'en' ? "/en/contact" : "/contact"}>{t('headerTwo.contact')}</Link></li>
                              </ul>
                           </nav>
                        </div>
                        <div onClick={handleShow} className={`side-menu-icon d-lg-none text-end ${stickyMenu ? null : 'sticky-hamburger-menu'}`} style={{ marginRight: "20px" }}>
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> </button>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-2 d-none d-lg-block d-xl-block">
                        <div className="header-right f-right">
                           <Link to={i18n.language === 'en' ? "/en/contact" : "/contact"} className="primary_btn btn-icon btn-icon-green"><span>
                              <a href={i18n.language === 'en' ? "/en/contact" : "/contact"}><i className="fas fa-envelope"></i></a></span>
                              {t('headerTwo.contactUs')}
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeTwoNavBar;